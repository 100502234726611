import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

import Form from "@/views/Form.vue";
import Followup from "@/views/Followup.vue";
import FormAnonymus from "@/views/FormAnonymus.vue";
import ListCode from "@/views/ShowCode.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/anonymous_report",
        name: "Anonymous",
        component: FormAnonymus,
    },
    {
        path: "/report",
        name: "Report",
        component: Form,
    },
    {
        path: "/followup",
        name: "Followup",
        component: Followup,
    },
    {
        path: "/listcode",
        name: "ShowCode",
        component: ListCode,
        props: true,
    },
    { path: "/:pathMatch(.*)*", component: NotFound },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

export default router;