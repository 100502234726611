
<template>

  <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
         <h1 class="display-5 fw-bold ">Canal de denúncia interna</h1>
        <h2 class="display-8 ">Denúncia com garantia de confidencialidade</h2>
        <div class="text-start fs-5 mt-5" id="start">

        <p class="text-start mt-5 fw-bold" >INFORMAÇÃO LEGAL E CONSENTIMENTO INFORMADO SOBRE TRATAMENTO DE DADOS PESSOAIS NA UC</p>
        <p class="text-start fs-5 ml-25">1. A finalidade da recolha dos meus dados pessoais pela Universidade de Coimbra (UC),
          no presente contexto, destina-se a apresentação de denúncia através do canal de denúncia interna criado pela UC, 
          em cumprimento do Regime Geral de Prevenção da Corrupção e do Regime Geral de Proteção de Denunciantes de Infrações,
          para fins de tratamento de denúncia nos termos do quadro legal referido;</p>
        <p class="text-start fs-5 ml-25">2. A responsável pelo tratamento é a UC, através do Gabinete de Auditoria e Prevenção de Riscos de Gestão da UC, 
          Rua Gomes Freire, nº 22 – r/c, 3000-204 Coimbra;</p>
        <p class="text-start fs-5 ml-25">3. Os dados pessoais solicitados pela UC, e por mim fornecidos, são apenas os dados considerados essenciais
          para as finalidades indicadas no ponto 1.; </p>
        <p class="text-start fs-5 ml-25">4. O prazo de conservação de dados pessoais será de cinco anos, nos termos do artigo 20º do Regime Geral de 
          Proteção de Denunciantes de Infrações;</p>
        <p class="text-start fs-5 ml-25">5. Não existem comunicações, interconexões e fluxos transfronteiriços dos dados pessoais identificáveis no destinatário;</p>
        <p class="text-start fs-5 ml-25">6. Na qualidade de titular dos dados, em conformidade com a Lei, tenho o direito de acesso, o direito de retificação, 
        o direito de apagamento, o direito de limitação do tratamento, o direito de portabilidade, o direito de oposição e direito de não ficar sujeito 
        a decisões automatizadas, podendo estes ser exercidos a qualquer momento, por escrito, mediante comunicação por correio eletrónico para 
         <a href="mailto:rt.gaprg@uc.pt" class="text-reset">rt.gaprg@uc.pt</a>.</p>
        <p class="text-start fs-5 ml-25">7. Sem prejuízo do direito ao apagamento previsto no número anterior, o presente consentimento, bem como eventual pedido posterior
          de retirada do mesmo, não poderão ser apagados enquanto estes forem legalmente exigíveis.</p>
        <p class="text-start fs-5 ml-25">8. Poderei contactar o Encarregado de Proteção de Dados da UC, por correio eletrónico,
           para  <a href="mailto:rt.epd@uc.pt"  class="text-reset"> epd@uc.pt</a>.</p><br>
        <p class="text-start">O Responsável pelo Tratamento dos dados pessoais garante o cumprimento do disposto no Regulamento Geral de Proteção de Dados, bem como na demais legislação aplicável, obrigando-se a respeitar e a cumprir os direitos expressos no ponto 6., quando aplicáveis,
           nos termos legais, e a não colocar à disposição de terceiros os dados pessoais de forma nominativa, sem a minha autorização pessoal.</p><br>
        <p class="text-start">Os pormenores sobre a “Política de Privacidade e de Proteção de Dados da UC”,
           estão disponíveis em <a href="https://www.uc.pt/protecao-de-dados/politica-de-privacidade/"   target="_blank" class="text-reset">www.uc.pt/protecao-de-dados/politica-de-privacidade </a>. </p><br>
        
        <div v-if="loading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="formkit-outer" data-type="checkbox" data-multiple="false" data-invalid="true" data-submitted="true">
          <label class="formkit-wrapper">
            <div class="formkit-inner">
              <input placeholder="" id="disclaimer" rows="1" accept="" description="" type="checkbox" class="formkit-input" name="autorizacao" v-model="disclaimer" @click="updateDisclaimer">

                <span class="formkit-decorator" aria-hidden="true"></span>
            </div>
              <span class="formkit-label">Autorizo o presente tratamento de dados pessoais</span>
          </label>
              <ul class="formkit-messages" aria-live="polite" ref="disclaimer">
                <li v-if="disclaimer_info" class="formkit-message" id="autorizacao21-rule_required" data-message-type="validation">Campo de preenchimento obrigatório</li>
              </ul>
        </div><br>
                
        <FormKit type="form" submit-label="Submeter" @submit="save" autocomplete="off" @click="showDisclaimer">
          <FormKitSchema :schema="createForm" />
            <div class="alert alert-warning" role="alert">
              <strong>Alerta!</strong><br>
              Após a submissão da denúncia, será gerado e comunicado um <u>código único e intransmissível</u> 
              que lhe permitirá ter <strong>acesso ao conteúdo da denúncia</strong> que efetuou, bem como <strong>fazer o seu seguimento</strong>, 
              nomeadamente, para os efeitos do previsto no artigo 11º do Regime Geral de Proteção de Denunciantes de Infrações.
            </div>
            <vue-recaptcha @verify="onVerify"  @expired="onCaptchaExpired" :sitekey="captchaKey" /><br>
        </FormKit>

        </div>
      </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {axios, APIUrl} from '../api/axios-base';

export default {
  components: { VueRecaptcha } ,
  name: 'Form',
  isDisabled: true,
  data() {
    return {  
      form: {
        loading:true,
        robot: false
      },
      createForm:[] ,
      disclaimer:false,
      disclaimer_info:false,
      checked:false,
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
    }
  },
  mounted() {
    this.getForm();
    setTimeout(() => {
      this.loadInfo();
    }, 500); 
  },
  methods: {
    async save(values) {
      if (!this.form.robot) {
        alert('Por favor, verifique o captcha')
        return false
      }
    
      if (!this.disclaimer) {
        document.querySelector('#disclaimer').scrollIntoView({behavior: 'smooth'});
        return false
      }
      
      if (values['causa'] != 'outro') {
          values["outro_qual"] = "";
      }
      values["nomeFormulario"] = "FormAn";
      await axios.post(APIUrl+'/resposta_formulario/', values,  { headers: {'Content-Type': 'multipart/form-data' }} ).then((response) => {
        if (response.data.success) {
          this.$router.push({ name: 'ShowCode', params: { code: response.data.ticketUid } })
        } else {
          console.log('Não foi possível gerar o código')
        }
      })
    },
    //Captcha validation    
    onVerify(response) {
      if (response) this.form.robot = true;
    },
    onCaptchaExpired () {
      this.form.robot = false;
    },
    getForm() {
     axios.get(APIUrl+'/get_formulario/?nomeFormulario=FormAn').then((response) => {
        this.createForm = response.data;
        this.loading = false;
      })
    },
    showDisclaimer() {
      if (!this.disclaimer) {
        this.disclaimer_info = true
      } else {
        this.disclaimer_info = false
      }
    },
    updateDisclaimer() {
       if (this.disclaimer) {
        this.disclaimer_info = true
      } else {
        this.disclaimer_info = false
      }
    },
    loadInfo() {
      var source = document.getElementsByClassName('formkit-option')
      console.log(source.length)
      console.log(source[source.length-1])
      let html = document.createElement("div");
      //html.innerHTML = '<br>CreateElement example';
    
      var extend_text = '<small><br><br>* Nos termos do artigo 3º do Regime Geral de Prevenção da Corrupção, entende-se por corrupção e infrações conexas os crimes de corrupção, '
        extend_text += 'recebimento e oferta indevidos de vantagem, peculato, participação económica em negócio, concussão, abuso de poder, prevaricação, tráfico de influência, '
        extend_text += 'branqueamento ou fraude na obtenção ou desvio de subsídio, subvenção ou crédito, previstos na Lei.</small><br>'
      html.innerHTML = extend_text;
      source[source.length-1].append(html)

    }
  }
}
</script>
<style>
.small, small {
    font-size: 0.775em;
}
</style>
