
<template>
  <p v-html="info"></p>
   <h2 v-if="environment" class="alert alert-warning">Ambiente de Desenvolvimento </h2>
 

</template>

<script>
import {axios, APIHeaderFooter} from '../api/axios-base'
export default {
name: 'Navbar',
data() {
 return {
   info: null,
    environment : process.env.NODE_ENV !== 'production' ? true : false,
   
 };
},


mounted() {
 axios.get(APIHeaderFooter +'/get_header/?aplicacao=denuncia').then((response) => {
    if  (response.data.error !== undefined) {
       this.loading = false;
       alert(response.data.error)
    }
    else {
      this.info = response.data.header;
    }
  }).catch((error) => {
    console.log(error)
  })
},

}
</script>