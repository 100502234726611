<template>
  <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold ">Canal de denúncia interna</h1>
        <div><br>
          
          <FormKit v-if="!showDetails" type="form" submit-label="Pesquisar"  @submit="find" autocomplete="off">
            <FormKitSchema :schema="formData" />
              <vue-recaptcha @verify="onVerify"  @expired="onCaptchaExpired" :sitekey="captchaKey" />
              <br>
          </FormKit>
          <br><br>
          <hr>
        </div>

        <div v-if="showDetails" class="text-start fs-5 mt-5" >
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
               <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                 <strong> Acompanhamento da denúncia</strong>
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse pre-formatted" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <div v-if="followup" class="col-md-10 ">
                    <ul class="timeline">
                      <li v-for="(activity, index) in  followup"  :key="index" class="timeline-item mb-5">
                        <h5  v-if="activity.estado_denuncia" class="fw-bold"> {{activity.estado_denuncia}}</h5>
                        <p v-if="activity.DataCriacao" class="text-muted mb-2 fw-bold">{{activity.DataCriacao}}</p>
                        <p v-if="activity.mensagem" class="text-muted"><strong>Mensagem:</strong> {{activity.mensagem}}  </p>
                      </li>
                    </ul>
                   <br>
                  </div>
                </div>
              </div>
            </div>
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                 <strong>Dados da denúncia</strong> 
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <FormKitSchema :schema="createForm"/>
                  <div v-if="showFiles" class="formkit-outer">
                    <div class="formkit-wrapper">
                      <label for="documentos" class="formkit-label">Documentos</label>
                      <ol class="list-group list-group-numbered">
                        <li  v-for="(file, index) in  listFiles"  :key="index"  class="list-group-item">
                          <a @click="downloadFile(file.id, file.nome)" class="link-secondary">{{file.nome}}</a> 
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>      
        </div>
      </div>
    </div>
</template>

<script>
//import axios from 'axios';
import {axios, APIUrl} from '../api/axios-base';
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'Form1',

   components: {
      VueRecaptcha
  } ,
  
   data() {
    return {  
        form: {
          robot: false
        },
        showDetails: false,
        createForm:{},
        formValues:{},
        followup: [],
        showFiles: false,
        listFiles:[],
        ticketIdTemp: null,
        formData :[
        {
          $el: 'h2',
          children: 'Seguimento da denúncia'
        },
        {
          $formkit: 'text',
          name: 'report_id',
          label: 'Introduza o código fornecido aquando da submissão da denúncia.',
          //help: 'Informe o ID da denúncia para acompanhar o estado.',

          validation: 'required|length:10'
        },
      ],
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
    }
    
    
    
  },
    mounted() {
        window.scrollTo(0,0);
    },
  methods: {
     find(ticket) {
       console.log(ticket.report_id)
      if (!this.form.robot) {
        console.log(this.form)
        alert('Por favor, verifique o captcha')
        return false
      }

      var axiosParams = {
          params : {
            'ticketUid' : ticket.report_id
          }
      }
      axios.get(APIUrl +'/resposta_formulario/',axiosParams).then((response) => {
        if  (response.data.error || response.data.length == 0) {
            //alert('Não foi encontrado nenhum registro com o código fornecido, verifique e tente novamente.')
            this.$router.go(this.$router.currentRoute)
        } else {
            this.createForm = response.data.denuncia;
            this.listFiles = response.data.documentos;
            this.ticketIdTemp =  ticket.report_id
            setTimeout(() => {
              this.loadInfo();
            }, 500); 
            if( Object.keys(response.data.documentos).length > 0 ){
              this.showFiles = true;
            }
            this.showDetails = true;
            this.getFollowup(axiosParams)
          }
        })
     
    },
    getFollowup(params) {
      axios.get(APIUrl +'/get_acompanha_denuncia/',params).then((response) => {
        this.followup = response.data.Interacoes;
        })
    },
    downloadFile(file_id, file_name) {
      if(file_id && file_name && this.ticketIdTemp){
        var axiosParams = {
          params : {
            'ticketUid' : this.ticketIdTemp,
            'id' : file_id,
          },
          responseType: "blob"
        }
         axios.get(APIUrl +'/get_file_resposta/',axiosParams).then((response) => {
           console.log('responseFile',response)
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name);
            document.body.appendChild(fileLink);
          fileLink.click();
        })
      }
      return false
    
    },
    onVerify(response) {
      if (response) this.form.robot = true;
    },
    onCaptchaExpired () {
      this.form.robot = false;
    },
    loadInfo() {
      var source = document.getElementsByClassName('formkit-option')
      console.log(source.length)
      console.log(source[source.length-1])
      let html = document.createElement("div");
      //html.innerHTML = '<br>CreateElement example';
    
      var extend_text = '<small><br><br>* Nos termos do artigo 3º do Regime Geral de Prevenção da Corrupção, entende-se por corrupção e infrações conexas os crimes de corrupção, '
        extend_text += 'recebimento e oferta indevidos de vantagem, peculato, participação económica em negócio, concussão, abuso de poder, prevaricação, tráfico de influência, '
        extend_text += 'branqueamento ou fraude na obtenção ou desvio de subsídio, subvenção ou crédito, previstos na Lei.</small><br>'
      html.innerHTML = extend_text;
      source[source.length-1].append(html)
    }
  }
}
</script>
<style>
.small, small {
    font-size: 0.775em;
}
</style>
