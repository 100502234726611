<template>
  <p v-html="info"></p>
</template>


<script>

import {axios, APIHeaderFooter} from '../api/axios-base'

export default {
 name: 'Footer',
 data() {
   return {
     info: null,
     year: new Date().getFullYear(),
     appName: process.env.VUE_APP_NAME,
     appVersion: process.env.VUE_APP_VERSION
   };
 },
 mounted() {


   axios.get(APIHeaderFooter +'/get_footer/').then((response) => {
      if  (response.data.error !== undefined) {
         this.loading = false;
         alert(response.data.error)
       }
       else {
         this.info = response.data.footer;
         this.info = this.info.replace("api_appName",this.appName).replace("api_appVersion",this.appVersion).replace("api_year",this.year);
       }
     }).catch((error) => {
       console.log(error)
     })
   },
}
</script>




