import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import "bootstrap/dist/js/bootstrap.js";
import '@formkit/themes/dist/genesis/theme.css';
//import { createDynamicForms } from '@asigloo/vue-dynamic-forms';
import "./assets/css/uc_stiles.min.css"
import "./assets/css/custom.min.css"


import { plugin, defaultConfig } from '@formkit/vue'
import { pt, en } from '@formkit/i18n'







//import '@asigloo/vue-dynamic-forms/dist/themes/material.scss';

//const VueDynamicForms = createDynamicForms();





export const appInstance = createApp(App);
appInstance.use(router);
//appInstance.use(VueDynamicForms);

appInstance.use(plugin, defaultConfig({
    // Define additional locales
    locales: { en, pt },
    // Define the active locale
    locale: 'pt',
}));

appInstance.mount("#app");