
<template>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">
                    Sorry, an error has occured, Requested page not found!
                </div><br>
                <div class="error-actions">
                    <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" type="button" @click="goTo('/')">Voltar ao início</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br><br>
</template>

<script>
import router from '../router'
export default {
  name: 'error',
  data() {
    return {  
      checked: false,
      isDisabled: false,
      fieldId: 'flexCheckDefault',
      form: {
        checked: false,
      },
    }
  },
  methods: {
      goTo(path) {
        router.push(path); 
      }
    }
}
</script>
