
<template>
  <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold ">Canal de denúncia interna</h1>
        <h2 class="display-8 ">Denúncia anónima </h2>
        <div v-if="loading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
                
        <div class="text-start fs-5 mt-5" id="start">
          <FormKit type="form" submit-label="Submeter"  @submit="save" autocomplete="off">
            <FormKitSchema :schema="createForm" />
            <div class="alert alert-warning" role="alert">
              <strong>Alerta!</strong><br>
              Após a submissão da denúncia, será gerado e comunicado um <u>código único e intransmissível</u> 
              que lhe permitirá ter <strong>acesso ao conteúdo da denúncia</strong> que efetuou, bem como <strong>fazer o seu seguimento</strong>, 
              nomeadamente, para os efeitos do previsto no artigo 11º do Regime Geral de Proteção de Denunciantes de Infrações.
            </div>
              <!-- CAPCHA LOCALHOST -->
              <vue-recaptcha @verify="onVerify"  @expired="onCaptchaExpired" :sitekey="captchaKey" />
              <br>
          </FormKit>
        </div>
       
        
      </div>

      
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {axios, APIUrl} from '../api/axios-base';


export default {
  name: 'Form1',
  isDisabled: true,
  components: { VueRecaptcha } ,
  data() {
    return {  
      loading:true,
      createForm:[] ,
      form: {
        robot: false
      },
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
    }
  },
  mounted() {
    this.getForm();
    setTimeout(() => {
      this.loadInfo();
    }, 500);
  },
  methods: {
     async save(values) {
      if (!this.form.robot) {
        alert('Por favor, verifique o captcha')
        return false
      }
 
      if (values['causa'] != 'outro') {
          values["outro_qual"] = "";
      }
     
      values["nomeFormulario"] = "FormAnonymous";
      await  axios.post(APIUrl+'/resposta_formulario/', values,  { headers: {'Content-Type': 'multipart/form-data' }} ).then((response) => {
        if (response.data.success) {
            this.$router.push({ name: 'ShowCode', params: { code: response.data.ticketUid } })
        } else {
          console.log('Não foi possível gerar o código')
        }
      })
    },
    onVerify(response) {
      if (response) this.form.robot = true;
    },
    onCaptchaExpired () {
      this.form.robot = false;
    },
    getForm() {
     axios.get(APIUrl +'/get_formulario/?nomeFormulario=FormAnonymous').then((response) => {
        this.createForm = response.data;
        this.loading = false
      })
    },
    loadInfo() {
      var source = document.getElementsByClassName('formkit-option')
      console.log(source.length)
      console.log(source[source.length-1])
      let html = document.createElement("div");
      //html.innerHTML = '<br>CreateElement example';
    
      var extend_text = '<small><br><br>* Nos termos do artigo 3º do Regime Geral de Prevenção da Corrupção, entende-se por corrupção e infrações conexas os crimes de corrupção, '
        extend_text += 'recebimento e oferta indevidos de vantagem, peculato, participação económica em negócio, concussão, abuso de poder, prevaricação, tráfico de influência, '
        extend_text += 'branqueamento ou fraude na obtenção ou desvio de subsídio, subvenção ou crédito, previstos na Lei.</small><br>'
      html.innerHTML = extend_text;
      source[source.length-1].append(html)

    }
  }
 
}
</script>
<style>
.small, small {
    font-size: 0.775em;
}
</style>
