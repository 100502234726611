import axios from 'axios'

import router from '../router'

const APIUrl = process.env.VUE_APP_API_URL
const APIHeaderFooter = process.env.VUE_APP_API_URL_HEADER_FOOTER

if (APIUrl == '') {
    console.log('Falta configurar url API')
}



axios.interceptors.response.use(response => response, error => {
    if (!error.response) {
        router.push('/netWorkFail')
        console.log(error)
        return error;
    }
});





export { axios, APIUrl, APIHeaderFooter }