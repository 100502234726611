
<template>
<div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold ">Canal de denúncia interna</h1><br>
        <!--h2 class="display-8 ">Canal de Denúncias</h2-->
    
        <p class="text-start fs-5">A Universidade de Coimbra repudia quaisquer manifestações que possam consubstanciar abuso de poder, assédio moral e sexual,
          conduta imprópria, conflitos de interesses, corrupção e infrações conexas, discriminação, fraude, furto e uso indevido de recursos da UC,
          entre outras práticas lesivas dos direitos de pessoas integrantes da Comunidade UC, dos interesses da UC ou que possam afetar negativamente a imagem da UC,
          assumindo o compromisso firme de atuar em conformidade e com proporcionalidade face às circunstâncias de cada situação reportada.</p><br>
        <p class="text-start fs-5">A apresentação de denúncias factualmente detalhadas e objetivas constitui um importante meio de prevenção,
          deteção e sancionamento de condutas impróprias.
        </p><br>
        <p class="text-start fs-5">A Universidade de Coimbra disponibiliza o presente canal de denúncia interna, em cumprimento do disposto
          no artigo 8.º do Regime Geral de Prevenção da Corrupção (RGPC) e nos termos do Regime Geral de Proteção de Denunciantes de Infrações (RGPDI).
        </p><br>
        <p class="text-start fs-5">Para que seja possível efetuar uma análise apropriada da denúncia, é determinante a sua apresentação com detalhe e de forma objetiva,
           facultando a descrição dos factos, as datas ou períodos de tempo abrangidos, os locais em que ocorreram, as pessoas e ou entidades envolvidas,
            e outros elementos considerados relevantes.
        </p><br>
        <p class="text-start fs-5">Nos termos do RGPDI, é considerada denunciante a pessoa singular que denuncie uma infração baseada em informações obtidas no âmbito
          da sua atividade profissional.
        </p><br>
        <p class="text-start fs-5">A denúncia pode ser efetuada por pessoas que integram a Comunidade UC e por pessoas externas à UC, existindo duas tipologias de denúncia
          disponíveis:
        </p>
        <p class="text-start fs-5 nml"> - a denúncia com garantia de confidencialidade – é garantida a confidencialidade do denunciante e os dados pessoais facultados pelo denunciante
          são tratados nos termos do estipulado no RGPDI, no cumprimento do previsto no Regulamento Geral da Proteção de Dados e nos termos da informação legal apresentada e do
          consentimento informado;
        </p>
        <p class="text-start fs-5 nml">- a denúncia anónima – não são recolhidos dados pessoais do denunciante, que apresenta a denúncia de forma anónima.</p><br>

        <p class="text-start fs-5">É ainda possível efetuar o seguimento da denúncia, conhecendo os respetivos desenvolvimentos, nos termos do artigo 11º do RGPDI, conforme apresentado abaixo.</p>

        <div class="text-start  mt-5 fw-bold">
          <!--div class="form-check">
            <input
            class="form-check-input"
            type="checkbox"
            :checked="checked"
            :id="fieldId"
            @change="check($event)">
            <label class="form-check-label" for="flexCheckDefault">
              Autorizo o presente tratamento de dados pessoais
            </label>
          </div-->

          <div class="col-8 offset-2">
            <p class="text-center">Para efetuar a sua denúncia clique numa das opções abaixo, consoante pretenda fazer denúncia anónima ou denúncia com garantia de confidencialidade</p>
            <div class="d-grid gap-3 col-md-5 mx-auto">
              <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" type="button" @click="anonymousForm()">Denúncia anónima</button>
              <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" @click="notAnonymousForm()" type="button">Denúncia com garantia de confidencialidade</button>
            </div>

          <!--div class="row">
            <div class="col-12 text-center">
              <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg btn-space" type="button" @click="anonymousForm()">Denúncia anónima</button>
              <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" @click="notAnonymousForm()" type="button">Denúncia com garantia de confidencialidade</button>
            </div>
          </div-->



          </div><br><br>

          <div class="col-8 offset-2">
            <p class="text-center">Se pretender acompanhar os desenvolvimentos associados à sua denúncia, aceda aqui</p>
            <div class="d-grid gap-3 col-md-5 mx-auto">
                <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" type="button"  @click="showFollowup()">Seguimento da denúncia</button>
            </div>
          </div>

        </div>
       
        
      </div>

    </div>
    
</template>

<script>
import router from '../router'
export default {
  name: 'Home',
  data() {
    return {  
      checked: false,
      isDisabled: false,
      fieldId: 'flexCheckDefault',
      form: {
        checked: false,
      },
    }
  },
     methods: {
      check(test) {
        if (test.target.checked) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
      anonymousForm(){
        router.push('/anonymous_report'); 
      },
      notAnonymousForm(){
        router.push('/report'); 
      },
      showFollowup(){
        router.push('/followup'); 
      }

  
  }
}
</script>

