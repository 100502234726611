
<template>
    <div class="p-5 mb-4 bg-light rounded-3" id="top">
        <div class="container-fluid py-5">
            <h2 class="display-8 fw-bold">Notificação de receção de denúncia e prestação de informação</h2><br>
            <div v-if="code">
                <h3 class="display-8">Confirma-se a receção da denúncia com o código:</h3><br>
                <h3 class="display-8 fw-bold">{{code}}</h3><br>
                <div class="alert alert-warning display-8 fw-bold" role="alert">
                    O código acima apresentado é imprescindível para futura consulta da sua denúncia e respetivo seguimento, pelo que deve guardá-lo.
                </div>
            </div>
            

            <p class="text-start mt-5 fw-bold" >De seguida, relativamente às denúncias submetidas que se reportem às infrações tipificadas no Regime Geral de Prevenção da Corrupção
            e do Regime Geral de Proteção de Denunciantes de Infrações (RGPDI), presta-se informação sobre os requisitos, autoridades competentes e 
            forma e admissibilidade da denúncia externa.</p><br>
            <p class="text-start" >Conforme estipulado no nº 2 do artigo 7º do RGPDI, o denunciante só pode recorrer a canais de denúncia externa quando: </p>
            <p class="text-start fs-5 nm">- Não exista canal de denúncia interna;</p>
            <p class="text-start fs-5 nm">- O canal de denúncia interna admita apenas a apresentação de denúncias por trabalhadores, não o sendo o denunciante;</p>
            <p class="text-start fs-5 nm">- Tenha motivos razoáveis para crer que a infração não pode ser eficazmente conhecida ou resolvida a nível interno ou que existe risco de retaliação;</p>
            <p class="text-start fs-5 nm">- Tenha inicialmente apresentado uma denúncia interna sem que lhe tenham sido comunicadas as medidas previstas ou adotadas na sequência da denúncia nos 
                prazos previstos no artigo 11.º; ou</p>
            <p class="text-start fs-5 nm">- A infração constitua crime ou contraordenação punível com coima superior a 50 000 €.</p><br>

            <p class="text-start">Atento o disposto no artigo 12.º do RGPDI, as denúncias externas são apresentadas às autoridades que, de acordo com as suas atribuições e competências, devam ou possam conhecer da matéria em causa na
            denúncia, incluindo: </p>
            <p class="text-start fs-5 nm">- O Ministério Público; </p>
            <p class="text-start fs-5 nm">- Os órgãos de polícia criminal; </p>
            <p class="text-start fs-5 nm">- As autoridades administrativas independentes;</p>
            <p class="text-start fs-5 nm">- As inspeções-gerais e entidades equiparadas e outros serviços centrais da administração direta do Estado dotados de autonomia administrativa. </p>
            <p class="text-start " >Caso a denúncia seja apresentada a autoridade incompetente, a denúncia é remetida oficiosamente à autoridade competente, disso se notificando o denunciante, sendo que, neste caso, considera-se como data da receção da denúncia a data em que a autoridade competente a recebeu. 
            Se a infração respeitar a crime ou a contraordenação, as denúncias externas podem sempre ser apresentadas através dos canais de denúncia externa do Ministério Público ou dos órgãos de polícia criminal, quanto ao crime, e das autoridades administrativas competentes ou das autoridades policiais e
            fiscalizadoras, quanto à contraordenação.</p><br>
            <p class="text-start">De acordo com o artigo 14.º, os canais de denúncia externa permitem a apresentação:
            </p>
            <p class="text-start fs-5 nm">- de denúncias por escrito e ou verbalmente, anónimas ou com identificação do denunciante;</p>
            <p class="text-start fs-5 nm">- de denúncia verbal por telefone ou através de outros sistemas de mensagem de voz e, a pedido do denunciante, em reunião presencial. </p>
            <p class="text-start">Caso as denúncias sejam recebidas por canais não destinados ao efeito ou por pessoas não responsáveis pelo seu tratamento, devem ser imediatamente transmitidas, sem qualquer modificação, a funcionário responsável. </p>
            <p class="text-start">As denúncias são arquivadas, não havendo lugar ao respetivo seguimento, quando as autoridades competentes, mediante decisão fundamentada a notificar ao denunciante, considerem que: </p>
            <p class="text-start fs-5 nm">- A infração denunciada é de gravidade diminuta, insignificante ou manifestamente irrelevante; </p>
            <p class="text-start fs-5 nm">- A denúncia é repetida e não contém novos elementos de facto ou de direito que justifiquem um seguimento diferente do que foi dado relativamente à primeira denúncia; ou </p>
            <p class="text-start fs-5 nm">- A denúncia é anónima e dela não se retiram indícios de infração. </p>
            <p class="text-start">O disposto sobre arquivamento de denúncias não prejudica as disposições próprias do processo penal e contraordenacional.</p><br>

            <br>
                <!--div class="col-md-8 offset-2 mr-5">
                    <div class="d-grid gap-3 col-5 mx-auto">
                        <button :disabled='isDisabled' class="btn btn-outline-primary btn-lg" type="button"  @click="goTo('/')">Voltar ao início</button>
                    </div>
                </div-->
        </div>
    </div>
</template>

<script>


import router from '../router'
export default {
    props : {
        code: {
            type: String,
           required: true
        }
    },

    mounted() {
        if(!this.code) {
            router.push('/')
        } 
        //document.querySelector('#top').scrollIntoView({behavior: 'smooth'});
       
       
    },
    methods: {
      goTo(path) {
        router.push(path); 
      }
    }
    
}
</script>
